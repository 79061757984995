<template>
  <div class="d-block w-100 px-1 pb-1" style="position: relative;">
    <div id="app-state-notice" class="d-flex w-100 flex-wrap" style="position: absolute;">
      <b-alert
        :show="isMounted && !isWorkerUnavailable && !isConnected"
        variant="warning"
        dismissible
        style="position: relative; top: 0; z-index: 1100;"
        class="w-100 mb-1"
      >
        {{ $t('eLt_err_pusherUnable') }} <b-link @click="retry">
          [{{ $t('eLt_btnRetry') }}]
        </b-link>
      </b-alert>
      <b-alert
        :show="isMounted && !isWorkerUnavailable && !isOnline"
        variant="warning"
        dismissible
        style="position: relative; top: 0; z-index: 1100;"
        class="w-100 mb-1"
      >
        {{ $t('eLt_err_offline') }} <b-link @click="retry">
          [{{ $t('eLt_btnRetry') }}]
        </b-link>
      </b-alert>
      <b-alert
        :show="isMounted && !isWorkerUnavailable && isWorkerLost && killCounter"
        variant="warning"
        dismissible
        style="position: relative; top: 0; z-index: 1100;"
        class="w-100 mb-1"
        @dismissed="stopTimer"
      >
        {{ $t('eLt_err_outdate', { killCounter }) }} <b-link @click="reload">
          [{{ $t('eLt_btnReload') }}]
        </b-link>
      </b-alert>
      <b-alert
        :show="isMounted && isWorkerUnavailable"
        variant="danger"
        dismissible
        style="position: relative; top: 0; z-index: 1100;"
        class="w-100 mb-1"
      >
        {{ $t('eLt_err_browserVer') }}
      </b-alert>
      <div v-if="isMounted && errors.length" class="w-100">
        <b-alert
          v-for="(e, i) in errors"
          :key="i"
          :show="isMounted"
          variant="danger"
          dismissible
          style="position: relative; top: 0; z-index: 1100;"
          class="w-100 mb-1"
        >
          <div v-if="e.title">
            {{ e.title }}
          </div>
          <div v-else>
            Unknown error
          </div>
          <div v-if="e.message" class="small">
            {{ e.message }}
          </div>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AppState',
  data () {
    return {
      killTimer: null,
      killCounter: null,
      isMounted: false
    }
  },
  computed: {
    ...mapFields({
      portId: 'shared.portId'
    }),
    ...mapGetters({
      errors: 'errors',
      isConnected: 'shared/isConnected',
      isOnline: 'shared/isOnline',
      isWorkerUnavailable: 'shared/isWorkerUnavailable',
      isWorkerLost: 'shared/isWorkerLost'
    })
  },
  watch: {
    isWorkerLost (n) {
      if (n && !this.killTimer && this.isMounted && !this.isWorkerUnavailable) {
        this.killCounter = 30
        this.killTimer = setInterval(() => {
          this.killCounter--
          if (this.killCounter === 0) {
            clearInterval(this.killTimer)
            this.killTimer = null
            this.killCounter = null
            location.reload()
          }
        }, 1000)
      }
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    ...mapActions({
      initNav: 'nav/init',
      setActivePath: 'nav/setActivePath',
      killWorker: 'shared/die'
    }),
    retry () {
      // kill
      this.killWorker()
    },
    reload () {
      clearInterval(this.killTimer)
      location.reload()
    },
    stopTimer () {
      clearInterval(this.killTimer)
      this.killTimer = null
      this.killCounter = null
    }
  }
}
</script>

<style scoped lang="scss">
  #app-state-notice {
    position: absolute;
    width: 100%;

    .alert {
      opacity: 0.95;

      &:hover {
          opacity: 1;
      }
    }
  }
</style>
